
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { Patient } from '@/models/Patient';
import { supplementsService } from '@/services/SupplementsService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import EmptyState from '@/components/EmptyState.vue';
import PatientAddSupplementModal from '@/components/supplements/PatientAddSupplementModal.vue';
import { PatientSupplement, PatientSupplementTableDto } from '@/models/Supplement';
import PatientSupplementsTable from './PatientSupplementsTable.vue';
import router from '@/router';

export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    EmptyState,
    PatientAddSupplementModal,
    PatientSupplementsTable,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const supplements: Ref<PatientSupplement[] | undefined> = ref();
    const loadingSupplements = ref(false);
    const patientId = computed(() => props.patient.id);
    const supplementPatientModalRef = ref();
    const filter = Array.isArray(route.query.filter) ? route.query.filter[0] : route.query.filter;

    const loadSupplementsList = async () => {
      loadingSupplements.value = true;
      const result = await supplementsService.findAllByPatient(patientId.value);
      if (!(result instanceof ServiceError)) {
        supplements.value = [...result].sort(
          (a, b) => dateFormat.getTimeFromDateString(b.creationDate) - dateFormat.getTimeFromDateString(a.creationDate),
        );
      }
      loadingSupplements.value = false;
    };

    const onAddSupplementClick = () => {
      const newSupplementPatient = {} as PatientSupplement;
      supplementPatientModalRef.value.openDialog(newSupplementPatient);
    };

    onMounted(async () => {
      await loadSupplementsList();
    });

    const patientSupplementTableDtos = computed<PatientSupplementTableDto[]>(() => {
      if (!supplements.value) {
        return [];
      }
      return supplements.value.map((supplement) => ({
        id: supplement.id,
        name: supplement.supplement.name,
        type: supplement.supplement.type,
        shoppingUrl: supplement.supplement.shoppingUrl,
        startDate: supplement.startDate,
        endDate: supplement.endDate,
        professional: supplement.professional.name + ' ' + supplement.professional.surname,
      }));
    });

    const onSupplementDetailClick = async (supplement: PatientSupplementTableDto) => {
      await router.push(`/patient/${patientId.value}/supplements/${supplement.id}`);
    };

    return {
      supplementPatientModalRef,
      supplements,
      loadingSupplements,
      route,
      patientSupplementTableDtos,
      filter,

      loadSupplementsList,
      onAddSupplementClick,
      onSupplementDetailClick,
    };
  },
});
